<template>
  <div>
    <div class="home">
      <div class="d-flex justify-content-between mb-5">
        <div></div>
        <h1 class="close" @click="close">X CLOSE</h1>
      </div>
      <div class="d-flex flex-wrap justify-content-center mx-auto work-wrapper">
        <img class="work-img" src="../../../assets/Humanity/Asset 97.png" alt="">
        <div class="work-info">
          <h1 class="work-title">Fallen</h1>
          <h2>36 x 48 inches</h2>
          <h2>Mixed media on canvas</h2>

          <button class="btn btn-dark btn-buy mt-4" @click="contact">BUY</button>

          <p class="mt-4">*Opens an external website</p>
        </div>
      </div>
    </div>
    <div class="footer mt-5">
      <h3 class="text-white">&copy; 2022 SALPONCEENRILE.COM</h3>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  },
  data() {
    return {
      
    }
  },
  methods: {
    handleSlideClick (dataset) {
      console.log(dataset.index, dataset.name)
    },

    about() {
      this.$router.push('/')
    },

    press() {
      this.$router.push('/press')
    },

    works() {
      this.$router.push('/works')
    },

    contact() {
      this.$router.push('/contact')
    },

    close() {
      this.$router.go(-1)
    }
  }
}
</script>
<style scoped>

.footer {
  background-color:black;
  width:100%;
  padding:30px;
  color:#666;
}

.home {
  padding:0px 60px;
  min-height:80vh;
}

.work-img {
  width:30em;
  object-fit: contain;
  margin:15px;
  box-shadow: -60px -60px black;
}
.work-title {
  font-size:35px;
  font-family: 'Montserrat-SemiBold';
  opacity: 1;
}

.work-info {
  margin-bottom:0;
  font-size:17px;
  text-align:left;
  width:25em;
  margin:15px;
}

.btn-buy {
  padding:10px;
  width:5em;
}

.close {
  font-family: 'Montserrat-Regular';
  font-size:30px;
  cursor:pointer;
}

@media screen and (max-width:1024px) {
  .home {
    padding:0px 60px;
    height:auto;
  }
}

@media screen and (max-width:450px) {
  .home {
    padding:20px 30px;
  }

  .work-img {
    width:25em;
    object-fit: contain;
    margin:15px;
    box-shadow: -20px -20px black;
  }

  .close {
    font-family: 'Montserrat-Regular';
    font-size:20px;
    cursor:pointer;
  }
}


</style>
