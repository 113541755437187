import { render, staticRenderFns } from "./'Mini Vision'.vue?vue&type=template&id=0a710125&scoped=true&"
import script from "./'Mini Vision'.vue?vue&type=script&lang=js&"
export * from "./'Mini Vision'.vue?vue&type=script&lang=js&"
import style0 from "./'Mini Vision'.vue?vue&type=style&index=0&id=0a710125&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a710125",
  null
  
)

export default component.exports