import { render, staticRenderFns } from "./'Dreamers'.vue?vue&type=template&id=71cbfb38&scoped=true&"
import script from "./'Dreamers'.vue?vue&type=script&lang=js&"
export * from "./'Dreamers'.vue?vue&type=script&lang=js&"
import style0 from "./'Dreamers'.vue?vue&type=style&index=0&id=71cbfb38&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71cbfb38",
  null
  
)

export default component.exports