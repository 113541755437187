<template>
  <div>
    <div class="home">
      <div class="justify-content-between nav">
        <b-nav class="justify-content-start" style="width:33%;">
          <b-nav-item @click="press">Press</b-nav-item>
          <b-nav-item>Exhibits</b-nav-item>
        </b-nav>
        <div style="width:33%;">
          <img alt="Vue logo" src="../../../assets/logo.png">
          <div class="title" @click="works"><h3>Works</h3></div>
        </div>
        <b-nav class="justify-content-end" style="width:33%;">
          <b-nav-item @click="about">About</b-nav-item>
          <b-nav-item @click="works">Works</b-nav-item>
          <b-nav-item @click="contact">Contact</b-nav-item>
        </b-nav>
      </div>
      <div class="works-wrapper mt-5">
        <div class="text-center">
          <h1 class="h1">Corrosion</h1>
          <h4 class="quote mt-3">Layout, in this suite, executed as modular rearrangement, is a key 
          feature of Sal’s works. Geometric shapes exist, after all, not in isolation but as part of
          a larger universe, as elements in a broader tapestry. It is these arrangements that inform 
          the message, not the visible details that fill the canvas. Layout is the sum of the palpable 
          and the ungraspable, the invisible hand not so much imposing order but a manner of framing.</h4>
        </div>
        <div class="d-flex flex-wrap justify-content-around mt-5">
          <div class="work" v-for="work in init.corrosion" :key="work.id" @click="viewWork(work.route)">
            <img :src="require('../../../assets/Corrosion/' + work.image)" alt="" class="work-img">
            <hr>
            <h5 class="work-title mt-3">{{ work.title }}</h5>
            <p class="work-info">{{ work.measurements }}</p>
            <p class="work-info">{{ work.description }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="footer mt-5">
      <h3 class="text-white">&copy; 2022 SALPONCEENRILE.COM</h3>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  },
  data() {
    return {
      
    }
  },
  computed: {
    init() {
        return this.$store.state.works;
    },
  },
  methods: {
    handleSlideClick (dataset) {
      console.log(dataset.index, dataset.name)
    },

    about() {
      this.$router.push('/')
    },

    press() {
      this.$router.push('/press')
    },

    works() {
      this.$router.push('/works')
    },

    contact() {
      this.$router.push('/contact')
    },

    viewWork(work) {
      this.$router.push({name: 'CorrosionWork', params: {work: work}})
    },
  }
}
</script>
<style scoped>
.title{
  color: black !important;
  font-weight: bolder !important;
  font-size: 20px;
}
.nav .active{
  color: black !important;
  font-weight: bolder !important;
}

.footer {
  background-color:black;
  width:100%;
  padding:30px;
  color:#666;
}

.nav-link {
  color:#a8afb7;
}

.active .nav-link {
  color:#181819;
  font-weight:bold;
}

.home {
  padding:20px 50px;
}

.works-wrapper {
  padding:0px 100px;
}

.h1 {
  font-size:40px;
  font-family:'Montserrat-SemiBold' !important;
}

.work {
  margin:30px;
  opacity: 1;
  transition:.4s;
  width:28em;
  height:38em;
  text-align:left;
  font-family:'Montserrat-Medium' !important;
  border-radius:10px;
  box-shadow: 5px 10px 18px #888888;
  padding:20px;
}

.work-img {
  height:28em;
  width:25em;
  object-fit: contain;
}

.work:hover {
  opacity:1;
}

.work-title {
  font-size:25px;
  opacity: 1;
}

.work-info {
  margin-bottom:0;
  font-size:17px;
}

.quote {
  font-size:25px;
  font-family:'Montserrat-Regular' !important;
}

@media screen and (max-width:800px) {
  .h1 {
    font-size:30px;
  }
}

@media screen and (max-width:450px) {
  .works-wrapper {
    padding: 0px;
  }

  .h1 {
    font-size:25px;
  }

  .work {
    margin:30px;
    opacity: 1;
    transition:.4s;
    width:25em;
    height:35em;
    text-align:left;
    font-family:'Montserrat-Medium' !important;
    border-radius:10px;
    box-shadow: 5px 10px 18px #888888;
    padding:20px;
  }

  .work-img {
    height:25em;
    width:22em;
    object-fit: contain;
  }
}

@media screen and (max-width:380px) {
  .home {
    padding:20px 30px;
  }
}


</style>
